//ITEMS
$(document).on('click','.addproductodocumento',function(){
    $("#listaProductosDocumento").modal("show");
    $("#listaProductosDocumentoLabel").empty();   
    $("#productos-lista-documento").empty(); 
    $("#listaProductosDocumentoLabel").append("Lista de productos");
    $.ajax({
        url: "productos/aux/",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#productos-lista-documento").append(`
                <div class="cabecera-lista" style='position:sticky; top:0; background-color:#fff; border-top:line solid thin'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:60%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Stock</p>
                            </div>
                            <div style='width:10%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Elegir</p>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data,function(index,value){
                if(value['stock'] > 0 && value['empresa_id'] == 1){
                    var html = `
                        <div class="registro-lista"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div style='width:60%'>
                                        <p class='m-auto text-center mb-1 capitalise'>`+value['nombre']+`</p>
                                    </div>
                                    <div style='width:15%'>
                                        <p class='m-auto text-center mb-1'>`+value['pvp']+`€</p>
                                    </div>
                                    <div style='width:15%'>
                                        <p class='m-auto text-center mb-1'>`+value['stock']+`ud</p>
                                    </div>
                                    <div class="text-center" style='width:10%'>
                                        <input type='checkbox' value='`+value['id']+`'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                    $("#productos-lista-documento").append(html);
                }
            });

        }
    });


});
$(document).on('click',"#confirmaddproductodocumento",function(){
    var productos = $("#listaProductosDocumento input");
    $.each(productos,function(index,value){
        if($(value).is(':checked')){
            $.ajax({
                url: "productos/"+$(value).val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $(".items-documento").append(`
                         <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                            <input name="input_item_id[]" class="d-none input-item-id" type='text' value='`+data['id']+`'>
                            <div class="registro-item-documento-cantidad">
                                <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='1.00' value='1'>
                            </div>    
                            <div class="registro-item-documento-nombre">
                                <input type="text" name='input_item_concepto[]' class='form-control m-auto text-center input-item-concepto' value='`+data['nombre']+`'>
                            </div>
                            <div class="registro-item-documento-precio">
                                <input name="input_item_precio[]" class="form-control m-auto text-center input-item-precio" type='number' step='0.01' value='`+(data['pvp'] / 1.21)+`'>
                            </div>
                            <div class="registro-item-documento-descuento">
                                <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento" type='number' step='0.01' value='0'>
                            </div>
                            <div class="registro-item-documento-importe">
                                <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center input-item-importe ' step='0.01' value='`+(data['pvp'] / 1.21)+`'>
                            </div>
                            <div class="registro-item-documento-borrar">
                                <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill"></i></a>
                            </div>
                        </div>
                    `); 
                    recalculoimporte();;
                },
                async: false
            });
        }
    });
    $("#listaProductosDocumento").modal('hide');
});
$(document).on('click','.addactividaddocumento',function(){
    $("#listaActividadesDocumento").modal("show");
    $("#listaActividadesDocumentoLabel").empty();   
    $("#actividades-lista-documento").empty(); 
    $("#listaActividadesDocumentoLabel").append("Lista de actividades");
    $.ajax({
        url: "actividades/aux/",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#actividades-lista-documento").append(`
                <div class="cabecera-lista" style='position:sticky; top:0; background-color:#fff; border-top:line solid thin'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:60%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                            </div>
                            <div style='width:10%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Elegir</p>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data,function(index,value){
                if(value['empresa_id'] == 1){
                    var html = `
                        <div class="registro-lista"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div style='width:60%'>
                                        <p class='m-auto text-center mb-1 capitalise'>`+value['nombre']+`</p>
                                    </div>
                                    <div style='width:15%'>
                                        <p class='m-auto text-center mb-1'>`+value['precio']+`€</p>
                                    </div>
                                    <div class="text-center" style='width:10%'>
                                        <input type='checkbox' value='`+value['id']+`'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                    $("#actividades-lista-documento").append(html);
                }
            });

        }
    });


});
$(document).on('click',"#confirmaddactividaddocumento",function(){
    var actividades = $("#listaActividadesDocumento input");
    $.each(actividades,function(index,value){
        if($(value).is(':checked')){
            $.ajax({
                url: "actividades/"+$(value).val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $(".items-documento").append(`
                         <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                            <input name="input_item_id[]" class="d-none input-item-id" type='text' value='`+data['id']+`'>
                            <div class="registro-item-documento-cantidad">
                                <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='1.00' value='1'>
                            </div>    
                            <div class="registro-item-documento-nombre">
                                <input type="text" name='input_item_concepto[]' class='form-control m-auto text-center input-item-concepto' value='`+data['nombre']+`'>
                            </div>
                            <div class="registro-item-documento-precio">
                                <input name="input_item_precio[]" class="form-control m-auto text-center input-item-precio" type='number' step='0.01' value='`+(data['precio'] / 1.21)+`'>
                            </div>
                            <div class="registro-item-documento-descuento">
                                <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento" type='number' step='0.01' value='0'>
                            </div>
                            <div class="registro-item-documento-importe">
                                <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center input-item-importe ' step='0.01' value='`+(data['precio'] / 1.21)+`'>
                            </div>
                            <div class="registro-item-documento-borrar">
                                <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill"></i></a>
                            </div>
                        </div>
                    `); 
                    recalculoimporte();;
                },
                async: false
            });
        }
    });
    $("#listaActividadesDocumento").modal('hide');
});
$(document).on('keyup','#filtro-box-servicios',function(){
    var value = $(this).val().toLowerCase();
		$("#servicios-lista  .registro-lista").filter(function() {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		});
});
$(document).on('click','.borrar-item',function(){
    $(this).parent().parent().remove();
    recalculoimporte();
});
$(document).on('change','.input-item-precio',function(){
    var valor_precio = $(this).val();
    var valor_descuento       = $(this).parent().parent().find(".input-item-descuento").val();
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-descuento',function(){
    var valor_descuento = $(this).val();
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-cantidad',function(){
    var valor_cantidad  = $(this).val();
    console.log(valor_cantidad);
    var valor_descuento = $(this).parent().parent().find(".input-item-descuento").val();
    console.log(valor_descuento);
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    console.log(valor_precio);
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-importe',function(){
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    var valor_descuento = (((valor_cantidad*valor_precio)-$(this).val())*100)/(valor_cantidad*valor_precio);
    $(this).parent().parent().find(".input-item-descuento").val(valor_descuento);
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','#porcentaje-retencion-documento',function(){
    recalculoimporte();
});
//FUNCIONES
function recalculoimporte(){
    var lineas = $('.registro-items');
    var retencion = $('#porcentaje-retencion-documento').val();
    var subtotal = 0.00;
    $.each(lineas,function(){
        if($(this).find('.input-item-importe').prop('disabled') == false){
            subtotal = subtotal + parseFloat($(this).find('.input-item-importe').val());
        }
    });
    $("#subtotal-documento").val(subtotal.toFixed(2));
    $("#retencion-documento").val((subtotal*retencion/100).toFixed(2));
    $("#iva-documento").val((subtotal*0.21).toFixed(2));
    $("#total-documento").val((subtotal-(subtotal*retencion/100)+(subtotal*0.21)).toFixed(2));
    
}


